import { api } from './api';
import { MDCRipple } from '@material/ripple';
import { MDCTextField } from '@material/textfield';
import { MDCTextFieldHelperText } from '@material/textfield/helper-text';
import { MDCDialog } from '@material/dialog';

import { language, languageMap } from './languages';

const dialog = new MDCDialog(document.querySelector('.mdc-dialog'));
const regexp =
  /^(?:[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
const textField = new MDCTextField(document.querySelector('.mdc-text-field'));
const helperText = new MDCTextFieldHelperText(document.querySelector('.mdc-text-field-helper-text'));
const emailButton = new MDCRipple(document.querySelector('#accept-invitation'));
const dialogButton = new MDCRipple(document.querySelector('#dialog-button'));

const dialogTitle = document.getElementById('dialog-title');
const dialogContent = document.getElementById('dialog-content');

emailButton.root.setAttribute('disabled', true);

const inputFunction = (event) => {
  const email = event.target.value;
  textField.foundation.adapter.notchOutline(language === 'no' ? 92 : 120);

  if (email.length !== 0) {
    emailButton.root.removeAttribute('disabled');
    textField.foundation.adapter.addClass('mdc-text-field--with-value');
  } else {
    emailButton.root.setAttribute('disabled', true);
    textField.foundation.adapter.removeClass('mdc-text-field--with-value');
  }
  textField.foundation.setValid(true);
  helperText.foundation.setContent('');
  textField.foundation.setTrailingIconContent('');
};

textField.foundation.adapter.floatLabel(true);
textField.foundation.adapter.notchOutline(language === 'no' ? 92 : 120);
textField.listen('input', inputFunction);

document.querySelector('input').addEventListener('focus', (event) => {
  const email = event.target.value;
  if (email.length === 0) {
    helperText.foundation.setContent('');
  }
});

document.querySelector('input').addEventListener('blur', (event) => {
  textField.foundation.adapter.floatLabel(true);
  inputFunction(event);
});

function sendEmail(event) {
  event.preventDefault();
  const email = document.getElementById('email').value;
  const isValid = regexp.test(email);
  textField.foundation.setValid(isValid);

  if (isValid) {
    helperText.foundation.setContent('');
    textField.foundation.setTrailingIconContent('');
  } else {
    if (email.length === 0) {
      helperText.foundation.setContent('');
      textField.foundation.setValid(true);
    } else {
      helperText.foundation.setContent(languageMap[language].emailIsInvalid);
      textField.foundation.setTrailingIconContent('  ');
    }
    return;
  }

  api
    .get(`/employee-invites/link?email=${getEncodedEmail(email)}`)
    .then((response) => {
      location.href = response.data.url;
    })
    .catch((error) => {
      dialog.open();
      if (error.response.status === 404) {
        dialogTitle.innerHTML = languageMap[language].thisEmailCouldNotBeLinkedTitle;
        dialogContent.innerHTML = languageMap[language].thisEmailCouldNotBeLinkedText;
      } else {
        dialogTitle.innerHTML = languageMap[language].thisInvitationIsNoLongerAvailableTitle;
        dialogContent.innerHTML = languageMap[language].thisInvitationIsNoLongerAvailableText;
      }
    });
}

function getEncodedEmail(email) {
  const [localPart, domain] = email.split('@');

  return `${encodeURIComponent(localPart)}@${domain}`;
}

const closeDialog = () => {
  dialog.close();
  setTimeout(() => {
    dialogTitle.innerHTML = '';
    dialogContent.innerHTML = '';
  }, 500);
};

emailButton.listen('click', sendEmail);
dialogButton.listen('click', closeDialog);
