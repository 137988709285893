import axios from 'axios';

let apiUrl = 'http://localhost:3000/api/v1';

switch (process.env.NODE_ENV) {
  case 'development':
    apiUrl = 'https://api.mia.lanars-dev.com/v1/';
    break;
  case 'stage':
    apiUrl = 'https://api.mia.lanars-stage.com/v1/';
    break;
  case 'production':
    apiUrl = 'https://api.miahealth.no/v1/';
    break;
  default:
    apiUrl = 'http://localhost:3000/api/v1';
    break;
}

export const api = axios.create({
  baseURL: apiUrl,
  timeout: 1000,
  headers: {
    'Content-Type': 'application/json',
  },
});
