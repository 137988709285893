export const en = {
  en: 'English',
  no: 'Norsk',
  joinOurOrganization: 'Join your organization on Mia Health!',
  getStartedBy: 'Get started by:',
  firstStep: 'Downloading the app and creating an account here👇',
  secondStep:
    'When you have created an account, connect to your organization by inputting your organization email and accepting this invitation on your mobile device.',
  organizationEmail: 'Organization email',
  yourOrganizationEmail: 'Your organization email',
  acceptInvitation: 'Accept invitation',
  areYouWonderingAboutSomething: 'Are you wondering about something?',
  contactUsOn: 'contact us on',
  thisEmailCouldNotBeLinkedTitle: 'This email could not be linked to this organization',
  thisEmailCouldNotBeLinkedText:
    'We could not link the email you inputted to this organization. Please recheck your email or contact your administrator for support.',
  thisInvitationIsNoLongerAvailableTitle: 'This invitation is no longer available',
  thisInvitationIsNoLongerAvailableText:
    'This link is not available at this time. Please contact your administrator for support.',
  gotIt: 'Got it!',
  emailIsInvalid: 'Email is invalid',
  areYouSureYouFollowedInstructions: 'Are you sure you followed the instructions?',
  ifYouAreOnMobile:
    'If you’re on your mobile device, please follow the instructions <a href="https://join.miahealth.no">here</a>',
  ifYouAreOnDesktop: 'If you’re on a desktop, grab your mobile device and visit <strong>join.miahealth.no</strong>',
};
