import {en, no} from './i18n';
import {MDCRipple} from '@material/ripple';
import {MDCMenu, Corner} from '@material/menu';
import {MDCTextField} from '@material/textfield';

export let language = 'no';
export const languageMap = {
  en,
  no,
};

const buttonRipple = new MDCRipple(document.querySelector('.mdc-button'));
const languageMenu = new MDCMenu(document.querySelector('.mdc-menu'));
languageMenu.setAnchorCorner(Corner.BOTTOM_LEFT);

const textField = new MDCTextField(document.querySelector('.mdc-text-field'));

const applyTranslations = (translations) => {
  const elements = document.querySelectorAll('[data-i18n]');
  elements.forEach((element) => {
    const key = element.dataset.i18n;
    if (element.tagName === 'INPUT') {
      element.placeholder = translations[key];
      return;
    }
    element.innerHTML = translations[key];
  });

  document.getElementById('apple-store-link').setAttribute('src', `public/images/app-store-${language}.svg`);
  document.getElementById('google-play-link').setAttribute('src', `public/images/google-play-${language}.svg`);

};

const languageButton = document.getElementById('language-button');

languageButton.addEventListener('click', () => {
  languageMenu.open = true;
});

languageMenu.listen('MDCMenu:selected', (event) => {
  const element = document.querySelector(`button > [data-i18n=${language}]`);
  const menuItem = event.detail.item;
  language = menuItem.getAttribute('data-value');

  element.innerHTML = language;
  element.setAttribute('data-i18n', language);
  document.documentElement.lang = language;

  applyTranslations(languageMap[language]);

  document.querySelector('.mdc-menu-item--selected').classList.remove('mdc-menu-item--selected');
  menuItem.classList.add('mdc-menu-item--selected');
  textField.foundation.adapter.notchOutline(language === 'no' ? 92 : 120);
});

applyTranslations(languageMap[language]);
