export const no = {
  en: 'Engelsk',
  no: 'Norsk',
  joinOurOrganization: 'Bli med din bedrift i Mia Health!',
  getStartedBy: 'Kom i gang ved å:',
  firstStep: 'Laste ned appen og opprette brukerkonto her 👇',
  secondStep:
    'Etter at du har opprettet brukerkonto, koble kontoen til bedriften din ved å godta denne invitasjonen på mobiltelefonen din.',
  organizationEmail: 'E-postadresse',
  yourOrganizationEmail: 'Din jobb-e-postadresse',
  acceptInvitation: 'Godta invitasjon',
  areYouWonderingAboutSomething: 'Lurer du på noe?',
  contactUsOn: 'Kontakt oss på',
  thisEmailCouldNotBeLinkedTitle: 'Denne e-postadressen kunne ikke knyttes til bedriften din',
  thisEmailCouldNotBeLinkedText:
    'Vi var ikke i stand til å koble e-postadressen du skrev inn, til bedriften din. Er du sikker på at du skrev riktig jobb-e-postadresse? Spør din lokale kontaktperson for å få hjelp.',
  thisInvitationIsNoLongerAvailableTitle: 'Denne invitasjonen er ikke tilgjengelig lenger',
  thisInvitationIsNoLongerAvailableText: 'Lenka er ikke tilgjengelig. Spør din lokale kontaktperson for å få hjelp.',
  gotIt: 'Den er grei!',
  emailIsInvalid: 'Ugyldig e-postadresse',
  areYouSureYouFollowedInstructions: 'Er du sikker på at du fulgte riktig oppskrift?',
  ifYouAreOnMobile:
    'Hvis du leser dette på mobiltelefonen din, skal du gjøre som beskrevet <a href="https://join.miahealth.no">her</a>',
  ifYouAreOnDesktop:
    'Hvis du sitter foran en datamaskin, må du bytte til mobiltelefon og åpne <strong>join.miahealth.no</strong>',
};
